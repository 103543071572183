import { Link, useRouteLoaderData } from 'react-router-dom';
import { projectPath } from '../models/Paths';
import Heading from '../viewer/components/styled/Heading';
import { Container } from '../viewer/components/styled/Container';

const Stage = () => {
  const stageData = useRouteLoaderData('stage');
  const { accountSlug, stageName, projects } = stageData;

  const alphaSort = (a, b) => a.name > b.name ? 1 : -1;
  return (
    <Container>
      <Heading>Stage</Heading>
      {stageName}
      <Heading>Projects</Heading>
      <ol>
        {projects.sort(alphaSort).map((project) =>
          <li key={`${project.id}`} className="flex flex-row justify-between items-center">
            <Link to={projectPath(accountSlug, stageName, project.name)}>
              {project.name}
            </Link>
            <span>
              {new Date(Date.parse(project.created_at)).toLocaleString()}
            </span>
          </li>
        )}
      </ol>
    </Container>
  );
}

export default Stage;
