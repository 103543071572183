import React from "react";
import stageLabel from "./utils/stageLabel";

const StageDropdownItem = ({ stage, defaultStageId }) => {
  if (!stage || !defaultStageId) {
    return null;
  }
  
  return (
    <div className="flex items-center justify-between w-full">
      <div className="font-medium text-gray-800 truncate">{stage.name}</div>
      {stageLabel(stage, defaultStageId)}
    </div>
  );
};
export default StageDropdownItem;