import React, { useState, useEffect } from "react";
import { Link, useRouteLoaderData } from "react-router-dom";

import { fetchStages, updateStage } from "../../api/stages";
import Heading from "../../viewer/components/styled/Heading";
import {
  Button,
  ButtonHighlight,
  ButtonSecondary,
} from "../../viewer/components/styled/Button";
import { stagePath } from "../../models/Paths";
import Spinner from "../../viewer/components/styled/Spinner";
import Toggle from "../../viewer/components/styled/Toggle";
import { updateAccount } from "../../api/accounts";
import { reorderStages } from "../../utils/reorder-stages/reorderStages";

const Stages = ({ canSetDefault = false, onSetDefault }) => {
  const [stages, setStages] = useState(null);
  const [showArchived, setShowArchived] = useState(false);
  let account = useRouteLoaderData("account");

  const isDefaultStage = (stage) => {
    return account.default_stage_id === stage.id;
  };

  useEffect(() => {
    const fetchData = async () => {
      setStages(await fetchStages(account.id, { archived: false }));
    };
    if (account) {
      fetchData();
    }
  }, [account]);

  const archiveStage = async (stageId, archived) => {
    await updateStage(stageId, { archived });
    refreshStages(showArchived);
  };

  // this needs to update account not stage
  const setAsDefaultStage = async (stageId) => {
    await updateAccount(account.id, { default_stage_id: stageId });
    account.default_stage_id = stageId;
    // reorder stages first stage should be the stage that is deafult in account and then by alphaebetical

    canSetDefault && onSetDefault();
    refreshStages(showArchived);
  };

  const refreshStages = async (showArchived) => {
    const params = {};
    if (!showArchived) {
      params["archived"] = false;
    }
    setStages(await fetchStages(account.id, params)); 
  };

  const handleShowArchivedClick = (event) => {
    setShowArchived(!showArchived);
    refreshStages(!showArchived);
  };

  const heading = "Stages";
  if (!stages) {
    return (
      <>
        <Heading>{heading}</Heading>
        <Spinner />
      </>
    );
  }

  return (
    <>
      <Heading>
        <div>{heading}</div>
        <Toggle
          label={"Show Archived"}
          value={showArchived}
          onClick={handleShowArchivedClick}
        ></Toggle>
      </Heading>
      <ol>
        {reorderStages(stages, account).map((stage) => (
          <li
            key={`${stage.name}`}
            className="flex flex-row justify-between hover:bg-gray-100 items-center p-1"
          >
            <Link to={stagePath(account.slug, stage.name)}>{stage.name}</Link>
            <div>
              {isDefaultStage(stage) && (
                <Button className="ml-2 w-28" disabled>
                  Default
                </Button>
              )}
              {!isDefaultStage(stage) && !stage?.archived && (
                <ButtonSecondary
                  className="ml-2 w-28"
                  onClick={() => {
                    setAsDefaultStage(stage.id);
                  }}
                >
                  Set Default
                </ButtonSecondary>
              )}
              {!isDefaultStage(stage) && (
                <ButtonHighlight
                  className="ml-2 w-28"
                  onClick={() => archiveStage(stage.id, !stage.archived)}
                >
                  {stage.archived ? "Unarchive" : "Archive"}
                </ButtonHighlight>
              )}
            </div>
          </li>
        ))}
      </ol>
    </>
  );
};

export default Stages;
