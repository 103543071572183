import { authHeaders, throwError } from './utils';

export const fetchDashboard = async (projectId, name) => {
    const response = await fetch(`/api/dashboards/?project_id=${projectId}&name=${name}`,
        { headers: authHeaders() },
    );
    if (response.status === 200) {
        const dashboards = await response.json();
        if (dashboards.length > 0) {
            return dashboards[0];
        } else {
            return null
        }
    } else {
        throwError("Error creating user", response.status)
    }
}