import { isEmpty } from "lodash";

const stageLabel = (stage = {}, defaultStageId) => {
  if (isEmpty(stage)) {
    return null;
  }

  const isDefaultStage = stage.id === defaultStageId;

  if (stage.archived) {
    return (
      <span
        className="text-xs w-20 text-center text-white bg-highlight-500 font-semibold rounded px-2 py-1"
        role="alert"
      >
        Archived
      </span>
    );
  }

  if (!isDefaultStage && !stage?.archived) {
    return (
      <span
        className="text-xs w-20 text-center text-white bg-secondary-500 font-semibold rounded px-2 py-1"
        role="alert"
      >
        Custom
      </span>
    );
  }

  if (isDefaultStage) {
    return (
      <span
        className="text-xs w-20 text-center text-white bg-primary-500 font-semibold rounded px-2 py-1"
        role="alert"
      >
        Default
      </span>
    );
  }

  return null;
};

export default stageLabel;
