const WarningLabel = ({ currentStage, defaultStage, accountSlug }) => {
  if (!currentStage | !currentStage.archived) {
    return null;
  }

  return (
      <div
        className="absolute left-1/2 transform -translate-x-1/2 flex items-center p-2 px-[20px] text-white rounded-lg bg-highlight-500"
        role="alert"
      >

        <span className="sr-only">Info</span>
        <div className="ms-3 text-sm font-medium">
        You are viewing a stage that was archived. If you want current data go to the{" "}
          <a className="font-semibold underline hover:no-underline" href={`/${accountSlug}/${defaultStage.name}`}>default stage</a>
        </div>
      </div>
  );
};

export default WarningLabel;
