import { useEffect } from 'react';
import { fetchAccounts } from '../api/accounts';
import { callRefreshAndNavigate } from '../models/RefreshSession';
import { useNavigate } from 'react-router-dom';

const useRedirectToAccount = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const accounts = await fetchAccounts()
                if (accounts.length === 1) {
                    navigate(`/${accounts[0].slug}`)
                } else {
                    navigate("/profile")
                }
            } catch (error) {
                if (error.status === 401) {
                    callRefreshAndNavigate(navigate);
                }
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default useRedirectToAccount;