import { isEmpty } from "lodash";

import { fetchProjects } from "../api/projects";
import { fetchStage } from "../api/stages";
import { fetchAccounts } from "../api/accounts";

export const loadDefaultStage = async (params) => {
  const accounts = (await fetchAccounts(params.params.accountSlug)) || [];
  
  if (isEmpty(accounts)) {
    return {};
  }

  if (accounts[0].default_stage_id === null) {
    return {
      stageName: "No Default Stage",
      defaultStage: null,
      accountSlug: params.params.accountSlug,
      projects: [],
    };
  }

  const defaultStage = await fetchStage(
    accounts[0].default_stage_id,
    accounts[0].id
  );  

  if (isEmpty(defaultStage)) {
    return {
      stageName: "No Default Stage",
      defaultStage: null,
      accountSlug: params.params.accountSlug,
      projects: [],
    };
  }

  const projects = await fetchProjects(null, null, defaultStage.name) || [];

  return {
    defaultStage,
    stageName: !isEmpty(defaultStage) ? params.params.stageName : "No Default Stage",
    accountSlug: params.params.accountSlug,
    projects,
  };
};
