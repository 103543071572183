import {
  Link,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import { isEmpty } from "lodash";
import { projectPath } from "../../models/Paths";
import { useState } from "react";
import Heading from "../../viewer/components/styled/Heading";
import Stages from "./Stages";

const alphaSort = (a, b) => (a.name > b.name ? 1 : -1);

const DefaultStageContainer = () => {
  const navigate = useNavigate();
  const defaultStageData = useLoaderData();
  
  const [defaultStageExists, setDefaultStageExists] = useState(!isEmpty(defaultStageData.defaultStage));
  
  const handleSetDefaultChange = async () => {
    if (!defaultStageExists) {
      setDefaultStageExists(true);
      navigate(".", { replace: true });
    }
  };
  
  if (!defaultStageExists) {
    return (
      <>
        <p>
          You do not have any default stages set. Please create and/or set one
        </p>
        <Stages
          canSetDefault={!defaultStageData.defaultStage}
          onSetDefault={handleSetDefaultChange}
          />
      </>
    );
  }
  
  return (
    <>
      <Heading>Stage</Heading>
      {defaultStageData.defaultStage?.name}
      <Heading>Projects</Heading>
      <ol>
        {defaultStageData.projects.sort(alphaSort).map((project) => (
          <li
            key={`${project.id}`}
            className="flex flex-row justify-between items-center"
          >
            <Link
              to={projectPath(defaultStageData.accountSlug, defaultStageData.defaultStage.name, project.name)}
            >
              {project.name}
            </Link>
            <span>
              {new Date(Date.parse(project.created_at)).toLocaleString()}
            </span>
          </li>
        ))}
      </ol>
    </>
  );
};

export default DefaultStageContainer;
