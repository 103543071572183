  // order stages by default stage first then alphabetical
  export const reorderStages = (stages, defaultStageId) => {
    return stages.sort((a, b) => {
      if (a.id === defaultStageId) {
        return -1;
      }
      if (b.id === defaultStageId) {
        return 1;
      }
      return a.name.localeCompare(b.name);
    });
  };
