import { useEffect, useState } from "react";
import { Link, useNavigate, useRouteLoaderData } from "react-router-dom";

import { Button } from "../viewer/components/styled/Button";
import NavBarStageDropDown from "./NavBarStageDropDown";
import GoToDefaultButton from "./GoToDefaultButton";

import { clearSession, hasNoSession } from "../models/Session";
import logo from "../viewer/images/logo.png";

const Navbar = () => {
  const navigate = useNavigate();

  const stage = useRouteLoaderData("stage") || {};
  const stageData = useRouteLoaderData("all-stages") || {};

  const {
    stages,
    defaultStageId,
    accountSlug,
    currentStageName: InitialStageName,
  } = stageData;

  const [currentStageName, setCurrentStageName] = useState(InitialStageName);

  useEffect(() => {
    setCurrentStageName(InitialStageName);
  }, [InitialStageName]);

  const handleLogout = () => {
    clearSession();
    navigate("/");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleChangeStageName = (stageName) => {
    setCurrentStageName(stageName);
  };

  const renderContent = () => {
    if (hasNoSession()) {
      return (
        <div className="flex items-center">
          <Button onClick={handleLogin}>Login</Button>
        </div>
      );
    } else {
      return (
        <>
          <div className="flex items-center">
            <img className="mr-2 h-8" src={logo} alt="Logo" />
            <NavBarStageDropDown
              stage={stage}
              stages={stages}
              defaultStageId={defaultStageId}
              accountSlug={accountSlug}
              currentStageName={currentStageName}
              handleChangeStageName={handleChangeStageName}
            />
          </div>
          <div className="absolute left-1/2 transform -translate-x-1/2 text-gray-500">
            <GoToDefaultButton
              stages={stages}
              currentStageName={currentStageName}
              defaultStageId={defaultStageId}
              accountSlug={accountSlug}
              handleChangeStageName={handleChangeStageName}
            />
          </div>
          <div>
            <Link className="ml-2 mr-2" to="/profile">
              Profile
            </Link>
            <Button className="ml-2" onClick={handleLogout}>
              Logout
            </Button>
          </div>
        </>
      );
    }
  };

  return (
    <nav className="relative w-full flex flex-wrap items-center justify-between py-4 px-4 mb-2 bg-gray-100 text-gray-600 hover:text-gray-900 focus:text-gray-900 shadow-sm navbar navbar-expand-lg navbar-light">
      <div className="flex justify-between items-center w-full">
        {renderContent()}
      </div>
    </nav>
  );
};

export default Navbar;
