import Account from './components/Account'
import Home from './components/Home'
import Profile from './components/Profile'
import Stage from './components/Stage'
import StageContainer from './components/StageContainer'
import DefaultStageContainer from './components/account/DefaultStageContainer'
import Register from './components/registration/Register'
import { loadAccount } from './loaders/account'
import { loadProject } from './loaders/project'
import { loadDefaultStage } from './loaders/defaultStage'
import { loadStage } from './loaders/stage'
import {loadStages} from './loaders/stage'
import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import ProjectContainer from './viewer/components/ProjectContainer'
import BreadcrumbLink from './viewer/components/styled/BreadcrumbLink'
import ErrorPage from './components/ErrorPage'
import Login from './components/registration/Login'
import ProtectedRoute from './routes/ProtectedRoute'
import Callback from './components/oauth/Callback'
import Index from './components/Index'
import Activate from './components/registration/Activate'
import Reset from './components/registration/password/Reset'
import Confirm from './components/registration/password/Confirm'
import AccountMemberships from './components/account/Memberships'
import AccountSubscription from './components/account/Subscription'
import AccountStages from './components/account/Stages'
import AccountSettings from './components/account/Settings'
import ProfileSettings from './components/profile/Settings'
import ProfileAccounts from './components/profile/Accounts'
import { loadProfile } from './loaders/profile'
import * as Sentry from "@sentry/react";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter,
);

const App = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Home />} errorElement={<ErrorPage />}>
      <Route index element={<Index />} />
      <Route path="register" element={<Register />} />
      <Route path="register/activate/:uid/:token" element={<Activate />} />
      <Route path="register/password/reset" element={<Reset />} />
      <Route
        path="register/password/confirm/:uid/:token"
        element={<Confirm />}
      />
      <Route path="login" element={<Login />} />
      <Route path="oauth/callback" element={<Callback />} />
      <Route id="all-stages" loader={loadStages} element={<ProtectedRoute />} errorElement={<ErrorPage />}>
        <Route
          path="profile"
          id="profile"
          loader={loadProfile}
          element={<Profile />}
        >
          <Route index element={<ProfileAccounts />} />
          <Route path="settings" element={<ProfileSettings />} />
        </Route>
        <Route
          path=":accountSlug"
          id="account"
          loader={loadAccount}
          handle={{
            crumb: (match) => (
              <BreadcrumbLink to={`/${match.data.slug}`}>
                {match.data.slug}
              </BreadcrumbLink>
            ),
          }}
        >
          <Route  element={<Account />}>
            <Route
              loader={loadDefaultStage}
              index
              element={<DefaultStageContainer />}
            />
            <Route path="stages" element={<AccountStages />} />
            <Route path="memberships" element={<AccountMemberships />} />
            <Route path="subscription" element={<AccountSubscription />} />
            <Route path="settings" element={<AccountSettings />} />
          </Route>
          <Route id="stages-archive" loader={loadStages} element={<StageContainer />}>
            <Route
              path=":stageName"
              id="stage"
              loader={loadStage}
              handle={{
                crumb: (match) => (
                  <BreadcrumbLink
                    to={`/${match.data.accountSlug}/${match.data.stageName}`}
                  >
                    {match.data.stageName}
                  </BreadcrumbLink>
                ),
              }}
            >
              <Route index element={<Stage />} />
              <Route
                id="project"
                path=":projectName"
                element={<ProjectContainer />}
                loader={loadProject}
                handle={{
                  crumb: (match) => (
                    <BreadcrumbLink
                      to={`/${match.params.accountSlug}/${match.params.stageName}/${match.data.name}`}
                    >
                      {match.data.name}
                    </BreadcrumbLink>
                  ),
                }}
              >
                <Route index element={<ProjectContainer />} />
                <Route
                  path=":dashboardName?/:projectId?/*"
                  element={<ProjectContainer />}
                  loader={loadProject}
                  handle={{
                    crumb: (match) => (
                      <BreadcrumbLink
                        to={`/${match.params.accountSlug}/${match.params.stageName}/${match.data.name}/${match.params.dashboardName}`}
                      >
                        {match.params.dashboardName}
                      </BreadcrumbLink>
                    ),
                  }}
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

export default App;
