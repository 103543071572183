import { Link } from "react-router-dom";
import { isEmpty } from "lodash";

import { reorderStages } from "../utils/reorder-stages/reorderStages";
import StageDropdownItem from "./StageDropdownItem";
import stageLabel from "./utils/stageLabel";

const NavBarStageDropDown = ({
  stages = [],
  stage,
  defaultStageId,
  accountSlug,
  currentStageName,
  handleChangeStageName,
}) => {
  if (isEmpty(stage) || stages.length === 0) {
    return null;
  }

  const stagesOrderedWithoutArchivedAndCurrent = reorderStages(
    stages.filter((stage) => !stage.archived && stage.name !== currentStageName)
  );

  const currentStage = stages.find((stage) => stage.name === currentStageName) || {};

  return (
    <div className="relative group inline-block w-64">
      <div className="flex items-center h-10 bg-gray-50 border border-gray-300 rounded-lg px-4 py-2 hover:shadow-md cursor-pointer w-full justify-between">
        <div className="flex items-center truncate">
          <div className="font-medium text-gray-800 truncate">
            {currentStage?.name || "Select Stage"}
          </div>
        </div>
        <div className="flex items-center space-x-2">
          {currentStage?.name && stageLabel(currentStage, defaultStageId)}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
      </div>
      <div className="absolute hidden group-hover:block bg-white border border-gray-200 rounded-lg shadow-lg top-full w-full z-10">
        <ul className="divide-y divide-gray-100">
          {stagesOrderedWithoutArchivedAndCurrent.map((stage) => (
            <li key={stage.name} className="hover:bg-gray-100">
              <Link
                to={`/${accountSlug}/${stage.name}`}
                className="block px-4 py-2 w-full"
                onClick={() => handleChangeStageName(stage.name)}
              >
                <StageDropdownItem
                  stage={stage}
                  defaultStageId={defaultStageId}
                />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NavBarStageDropDown;
