import Input from "../../viewer/components/styled/Input"
import Heading from "../../viewer/components/styled/Heading"
import { Button, ButtonOutline, GoogleSvg } from "../../viewer/components/styled/Button";
import React, { useState, useEffect } from "react";
import CenterForm from "../styled/CenterForm";
import { Link, useNavigate } from "react-router-dom";
import { getGoogleAuthUrl, loginUser } from "../../api/register";
import { storeSession } from "../../models/Session";
import { Error } from "../styled/Alerts";

const Login = () => {
    const [email, setEmail] = useState("")
    const [disabled, setDisabled] = useState(true)
    const [password, setPassword] = useState("")
    const [message, setMessage] = useState(null)
    const navigate = useNavigate()

    const handleEmailChange = (event) => {
        setEmail(event.target.value)
    }
    const handlePasswordChange = (event) => {
        setPassword(event.target.value)
    }

    useEffect(() => {
        if (password.length === 0 || email.length === 0) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [password, email]);

    const googleLogin = async () => {
        const authorizationUrl = await getGoogleAuthUrl()
        window.location.href = authorizationUrl["authorization_url"]
    };

    const login = async () => {
        if (disabled) {
            return
        }
        try {
            const response = await loginUser(email, password)
            if (response.access) {
                storeSession(response)
                navigate("/profile")
            } else {
                setMessage("Login failed.  No matching user.  Try again or use social login.")
            }
        } catch {
            setMessage("There was an error logging in.")
        }
    }

    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            login()
        }
    };

    const renderMessage = () => {
        if (!message) {
            return null
        }
        return (
            <Error>{message}</Error>
        )
    }

    return (
        <CenterForm>
            <Heading>Sign into your account</Heading>
            {renderMessage()}
            <div className="space-y-4 md:space-y-6">
                <ButtonOutline className="flex w-full justify-center" onClick={googleLogin}><GoogleSvg /><span className="ml-1">Sign in with Google</span></ButtonOutline>
                <hr></hr>
                <Input label="Email" name="email" value={email} onChange={handleEmailChange} onKeyDown={handleEnter} alt="email"></Input>
                <Input label="Password" name="password" value={password} onChange={handlePasswordChange} onKeyDown={handleEnter} type="password" alt="password"></Input>
                <span className="text-sm font-light mt-0 text-gray-500 dark:text-gray-400">
                    Forgot your password? <Link to="/register/password/reset" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Reset</Link>
                </span>

                <Button type="submit" name="login" className="w-full" disabled={disabled} onClick={login}>Login</Button>
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                    Don’t have an account yet? <Link to="/register" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</Link>
                </p>
            </div>
        </CenterForm >
    )
}

export default Login;