import { fetchProjects } from "../api/projects";
import { fetchStages } from "../api/stages";
import { fetchAccounts } from "../api/accounts";

export const loadStage = async (params) => {
  const stageName = params.params.stageName;

  const projects = await fetchProjects(null, null, stageName);

  return {
    stageName: params.params.stageName,
    accountSlug: params.params.accountSlug,
    projects,
  };
};

export const loadStages = async (params) => {
  const accounts = await fetchAccounts(params.params.accountSlug);

  const accountId = accounts[0]?.id;
  const defaultStageId = accounts[0].default_stage_id;
  const accountSlug = accounts[0].slug;
  const stages = await fetchStages(accountId);
  
  const currentStageName = params.params.stageName;

  return {
    stages,
    defaultStageId,
    accountSlug,
    currentStageName,
  };
};
